import {
  WppActionButton,
  WppIconEdit,
  WppIconMore,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'

import { CurrenciesListSortBy } from 'api/masterData/fetchers/fetchCurrenciesList'
import { ColDef, tableActions, TableInfinite } from 'components/common/table'
import { useOnSelectionChanged } from 'components/common/table/hooks/useOnRowSelected'
import { TableKey } from 'constants/table'
import { useDateFormat } from 'hooks/useDateFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { showDeleteCurrencyModal } from 'pages/currencies/deleteCurrencyModal/DeleteCurrencyModal'
import { getMinorUnitKeyValue, useCurrenciesLoader } from 'pages/currencies/utils'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { Currency } from 'types/masterData/currencies'
import { LocationState } from 'types/masterData/state'
import { CommonFilterState, MergeFilterState } from 'types/masterData/utils'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

interface Props {
  stateObj: ReturnType<typeof useSetState<CommonFilterState<MergeFilterState>>>
}

export const TableListCurrencies = ({ stateObj }: Props) => {
  const [{ search, isEmpty, selectedRows }, setState] = stateObj
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatDate } = useDateFormat()

  const columnDefs = useMemo<ColDef<Currency>[]>(
    () => [
      {
        width: 40,
        colId: 'select',
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        onCellClicked: ({ node }) => {
          node.setSelected(!node.isSelected(), false, 'checkboxSelected')
        },
      },
      {
        flex: 0.8,
        colId: CurrenciesListSortBy.name,
        sortable: true,
        sort: 'asc',
        headerName: t('tables.currencies.columns.name'),
        valueGetter: ({ data }) => data!.name ?? null,
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 0.7,
        colId: CurrenciesListSortBy.aliases,
        headerName: t('tables.currencies.columns.aliases'),
        valueGetter: ({ data }) => data!.aliases.join(', ') ?? null,
        tooltipValueGetter: ({ data }) => data!.aliases.join(', ') ?? '-',
      },
      {
        flex: 0.45,
        sortable: true,
        colId: CurrenciesListSortBy.isoAlpha3,
        headerName: t('tables.currencies.columns.isoAlpha3'),
        valueGetter: ({ data }) => data?.isoAlpha3 ?? null,
        tooltipValueGetter: ({ data }) => data?.isoAlpha3 ?? '-',
      },
      {
        flex: 0.45,
        sortable: true,
        colId: CurrenciesListSortBy.numCode,
        headerName: t('tables.currencies.columns.numCode'),
        valueGetter: ({ data }) => data?.numCode ?? null,
        tooltipValueGetter: ({ data }) => data?.numCode ?? '-',
      },
      {
        flex: 0.45,
        colId: CurrenciesListSortBy.minorUnit,
        headerName: t('tables.currencies.columns.minorUnit'),
        valueGetter: ({ data }) =>
          t(`master_data.currencies.minor_unit.${getMinorUnitKeyValue(data?.minorUnit!).toLocaleLowerCase()}`),
        tooltipValueGetter: ({ data }) =>
          t(`master_data.currencies.minor_unit.${getMinorUnitKeyValue(data?.minorUnit!).toLocaleLowerCase()}`),
      },
      {
        flex: 0.63,
        colId: CurrenciesListSortBy.withdrawalDate,
        headerName: t('tables.currencies.columns.withdrawalDate'),
        valueGetter: ({ data }) => data?.withdrawalDate ?? null,
        tooltipValueGetter: ({ data }) => data?.withdrawalDate ?? '-',
      },
      {
        flex: 0.53,
        colId: CurrenciesListSortBy.updatedAt,
        sortable: true,
        valueGetter: ({ data }) => formatDate(data?.updatedAt!) || formatDate(data?.createdAt!),
        headerName: t('tables.currencies.columns.updated'),
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem
                onWppChangeListItem={() =>
                  navigate(routesManager.masterData.currencies.update.getURL({ entryId: data!.id }), {
                    state: { data },
                  })
                }
              >
                <WppIconEdit slot="left" />
                <span slot="label">{t('common.edit')}</span>
              </WppListItem>

              <WppListItem
                onWppChangeListItem={() => showDeleteCurrencyModal({ currency: data! })}
                data-testid="delete-currency"
              >
                <WppIconRemoveCircle slot="left" />
                <span slot="label">{t('common.remove')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, navigate, formatDate],
  )

  const { loader } = useCurrenciesLoader({ search })

  const onSelectionChanged = useOnSelectionChanged<Currency>({ setState })

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmptySearch: isEmpty, search })

  const handleOnRowClicked = useStableCallback(async ({ event, data }: RowClickedEvent<Currency>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target, ['[role="gridcell"][col-id="select"]'])) {
      navigate(routesManager.masterData.currencies.update.getURL({ entryId: data.id }), {
        state: { data, filters: { search } } satisfies LocationState,
      })
    }
  })

  return (
    <TableInfinite
      tableKey={TableKey.MASTER_DATA_CURRENCIES}
      className={clsx('currencies-table', styles.table)}
      columnDefs={columnDefs}
      noRowsOverlayComponent={noRowsOverlayComponent}
      onSelectionChanged={onSelectionChanged}
      onRowClicked={handleOnRowClicked}
      loader={loader}
      getRowId={params => params.data.id}
      onLoadSuccess={({ isEmptySource }) => {
        setState({ isEmpty: isEmptySource, isEmptySearch: isEmptySource && !!search })
        tableActions.selectRows(TableKey.MASTER_DATA_CURRENCIES, selectedRows)
      }}
    />
  )
}
