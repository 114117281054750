import { MayBeNull } from '@wpp-open/core'
import fastDeepEqual from 'fast-deep-equal/es6'
import { filesize } from 'filesize'
import { useEffect, useMemo } from 'react'

const isEmptyObject = (object: object) => fastDeepEqual(object, {})
export const bytes = (n: number, unit: 'kb' | 'mb' | 'gb', baseType: 'binary' | 'decimal' = 'binary'): number => {
  let base = 1024
  if (baseType === 'decimal') {
    base = 1000
  }

  if (unit === 'kb') {
    return n * base
  }

  if (unit === 'mb') {
    return bytes(n * base, 'kb', baseType)
  }

  return bytes(n * base, 'mb', baseType)
}

export const formatBytes = (bytes: number) => filesize(bytes, { base: 2, standard: 'jedec' }) as string

export const isFile = (value: unknown): value is File => value instanceof File

export const downloadJson = (jsonData: Record<string, any>, fileName: string) => {
  // Convert the JSON object to a string with proper formatting (indented with 2 spaces)
  const dataStr: string = JSON.stringify(jsonData, null, 2)

  // Create a new Blob object containing the JSON string, with the 'application/json' MIME type
  const blob: Blob = new Blob([dataStr], { type: 'application/json' })

  // Create a URL for the Blob object to be used as a download link
  const url: string = URL.createObjectURL(blob)

  // Create an invisible anchor element to trigger the download
  const link: HTMLAnchorElement = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)

  // Trigger the download by simulating a click on the anchor element
  link.click()

  // Revoke the URL to release the memory that the browser allocated for it
  URL.revokeObjectURL(url)
}

export const readJsonFile = <T = any>(file: File): Promise<T> =>
  new Promise<T>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      try {
        resolve(JSON.parse(e.target!.result as string))
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsText(file)
  })

export const useInMemoryFileUrl = (file: MayBeNull<File | Blob>) => {
  const fileUrl = useMemo(() => {
    if (file && !isEmptyObject(file)) {
      return URL.createObjectURL(file)
    }
  }, [file])

  useEffect(
    () => () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl)
      }
    },
    [fileUrl],
  )

  return fileUrl
}
