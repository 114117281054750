import { facadeApi } from 'api'

interface Params {
  signedUrl: string
  file: File
  maxFileSizeInBytes?: number
}

const MAX_FILE_SIZE_IN_BYTES = 2097152

export const uploadMasterDataFileApi = ({ signedUrl, file, maxFileSizeInBytes }: Params) =>
  facadeApi.put<''>(signedUrl, file, {
    headers: {
      'Content-Type': file.type || 'application/octet-stream',
      'x-goog-content-length-range': `1,${maxFileSizeInBytes || MAX_FILE_SIZE_IN_BYTES}`,
    },
  })
