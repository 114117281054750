import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useDeleteCurrencyApi } from 'api/masterData/mutations/useDeleteCurrencyApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { handleReloadCurrenciesListTable } from 'pages/currencies/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Currency } from 'types/masterData/currencies'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  currency: Currency
  onAfterDelete?: () => void
}

const DeleteCurrencyModal = ({ id, currency, isOpen, onClose, onCloseComplete, onAfterDelete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleDeleteItem, isLoading } = useDeleteCurrencyApi()

  const handleDelete = async () => {
    try {
      await handleDeleteItem({
        id: currency?.id!,
      })

      await handleReloadCurrenciesListTable()
      onAfterDelete?.()

      enqueueToast({
        message: t('common.toasts.remove', { entity: t('common.master_data.entities.currency') }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
      data-testid={id}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          <WppIconWarning />
          {t('master_data.remove_item.header', {
            entity: t('common.master_data.lowercase_entities.currency'),
          })}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('master_data.remove_item.description', {
          entity: t('common.master_data.lowercase_entities.currency'),
        })}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('common.cancel')}
        </WppButton>
        <WppButton
          onClick={handleDelete}
          size="s"
          variant="destructive"
          loading={isLoading}
          data-testid="delete-currency-confirm"
        >
          {t('common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteCurrencyModal } = createNiceModal(DeleteCurrencyModal, 'delete-currency-modal')
